
	frappe.templates['navbar'] = `<head>
    <link type="text/css" rel="stylesheet" href="/apps/stanch/stanch/public/css/commonstyles.css">
    

</head>

<!-- <div class="alert alert-warning alert-dismissible fade mb-0 d-flex justify-content-center align-items-center show" role="alert" id="bannerMessage">
    <div class="d-flex justify-content-center align-items-center w-100">
        <p class="mb-0 me-3 text-center">
            <b>Exciting News! 🎉 → </b> We’re rebranding! <b>Stanch.io</b> is now <b>Entries.ai</b>. 
            A fresh name with the same commitment to innovation and excellence.
        </p>
    </div>
    <button type="button" class="custom-close" onclick="closeBanner()">✖</button>
</div> -->

<style>
    .custom-close {
        background: none;
        border: none;
        font-size: 16px;
        font-weight: bold;
        color: #333;
        cursor: pointer;
        padding: 5px;
        margin-left: 10px;
        transition: color 0.3s ease;
    }

    .custom-close:hover {
        color: red;
    }
</style>

<script>
    function closeBanner() {
        document.getElementById("bannerMessage").remove();
    }
</script>


<div class="alert alert-warning alert-dismissible fade mb-0 d-flex justify-content-center align-items-center show" role="alert" id="bannerPopUp" style="display: none!important;">
	<div class="d-flex justify-content-center align-items-center w-100">
	  <p class="mb-0 me-3 text-center">Your free plan storage is running out. Upgrade now to get unlimited storage!</p>
	  <button class="btn btn-danger btn-sm" onclick="payNow()">Pay Now</button>
	</div>
</div>
  

<script>
	function payNow() {
		const currentURL = location.origin;
		window.open(currentURL+"/sales_invoice_pay", "_blank");
	}
</script>

  

  <!-- <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script> -->
  
  <style>
	.alert {
	  text-align: center;
	  padding: 10px 20px;
	  font-size: 0.9rem;
	}
  
	.alert .btn {
	  margin-left: 10px;
	  padding: 5px 10px; 
	  font-size: 0.85rem;
	}
  
	.alert .btn-close {
	  position: absolute;
	  top: 5px;
	  right: 5px;
	  font-size: 0.75rem; 
	}
  
	.alert .d-flex {
	  flex-direction: row; 	
	}
  </style>
  
  
<header class="sticky-top">
	<script src="https://cdnjs.cloudflare.com/ajax/libs/qrious/4.0.2/qrious.min.js"></script>
	<div class="navbar gpnav navbar-expand" role="navigation">
		<div class="container">
			<a class="navbar-brand navbar-home p-0" href="/app/employee-dashboard-1">
				<img src="/assets/stanch/stanch-login/images/stanch_favicon.svg?v4" alt="Stanch" style="height: 30px !important" />
			</a>
			<div class="frappe-control input-max-width" data-fieldtype="Select" data-fieldname="current_company" title="current_company">
				<div class="form-group" style="margin-bottom: auto">
					<div class="control-input-wrapper">
						<div class="control-input flex align-center">
							<select type="text" autocomplete="off" class="input-with-feedback form-control ellipsis" maxlength="140" data-fieldtype="Select"
								data-fieldname="default_company" placeholder="Select Company" data-doctype="Session Defaults"
								style="min-width: 240px;max-width: 240px;border-radius: 3px !important;line-height: 1;padding: 0 23px 0 4px!important;height: 28px;background: #fff !important;margin-left: 15px;">
								<option value="Select Company" selected="true" disabled="disabled" style="width: 250px"> Select Company </option>
							</select>
							<div class="select-icon">
								<svg class="icon icon-sm" style="margin-top: -8px">
									<use class="" href="#icon-select"></use>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ul class="nav navbar-nav d-none d-sm-flex" id="navbar-breadcrumbs"></ul>

			<div class="collapse navbar-collapse justify-content-end">
				<!-- Create Button -->
				<ul class="navbar-nav toprightmenu">
					<li class="nav-item ">
						<span class="text-muted " id="cout" style="font-weight:bolder;"></span>
						<span class="text-muted " id="cin" style="font-weight:bolder;" ></span>
					</li>
					
					<li class="nav-item ml-2">
						<a class="nav-link timelink checkin_time" id="checkinout" href="javascript:;"
							onclick="aCheckinout()">
							Check In
						</a>
					</li>

					<li class="nav-item ">
						<a class="nav-link" href="javascript:;">
							<span id="qrcode-icon">
								<i class="icontop fas fa-mobile-screen"></i>
							</span>
						</a>
					</li>

					<li class="nav-item dropdown">
						<a class="nav-link" data-toggle="dropdown" href="javascript:;">
							<i class="icontop fas fa-circle-plus text-primary"></i>
						</a>
						<div class="dropdown-menu dropdown-menu-right">
							{% for item in navbar_settings.create_dropdown %}
								{% if (!item.hidden && frappe.boot.enable_full_feature && frappe.model.can_create(item.document_type || item.item_label)) { %}
									<a class="dropdown-item" href="{{ item.route }}">
										{%= __(item.item_label) %}
									</a>
								{% } %}
							{% endfor %}
						</div>
					</li>

					<div class="dropdown-menu dropdown-menu-right">
						{% for item in navbar_settings.create_dropdown %} {% if (!item.hidden &&
						frappe.boot.enable_full_feature && frappe.model.can_create(item.document_type ||
						item.item_label)) { %}
						<a class="dropdown-item" href="{{ item.route }}">
							{%= __(item.item_label) %}
						</a>
						{% } %} {% endfor %}
						
                        </div>
                    </li>
                    <!-- <li class="vertical-bar d-none d-sm-block"></li>
                    <li class="nav-item dropdown dropdown-help dropdown-mobile d-none d-lg-block">
					<a class="nav-link" data-toggle="dropdown" href="javascript:;">
						<i class="icontop bi bi-question-circle-fill"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-right" id="toolbar-help" role="menu">
						<div id="help-links"></div>
						<div class="dropdown-divider documentation-links"></div>
						{% for item in navbar_settings.help_dropdown %} {% if (!item.hidden) { %} {% if (item.route)
						{ %}
						<a class="dropdown-item" href="{{ item.route }}">
							{%= __(item.item_label) %}
						</a> {% } else if (item.action) { %}
						<a class="dropdown-item" onclick="return {{ item.action }}">
							{%= __(item.item_label) %}
						</a> {% } else { %}
						<div class="dropdown-divider"></div>
						{% } %} {% } %} {% endfor %}
					</div>
                    </li> -->

                    <li class="nav-item dropdown dropdown-notifications dropdown-mobile hidden">
					<a class="nav-link notifications-icon" data-toggle="dropdown" aria-haspopup="true"
						aria-expanded="true" href="javascript:;">
						<span class="notifications-seen">
							<i class="icontop far fa-bell"></i>
						</span>
						<span class="notifications-unseen">
							<svg class="icon icon-md">
								<use href="#icon-notification-with-indicator"></use>
							</svg>
						</span>
					</a>
					<div class="dropdown-menu notifications-list dropdown-menu-right" role="menu">
						<div class="notification-list-header">
							<div class="header-items"></div>
							<div class="header-actions"></div>
						</div>
						<div class="notification-list-body">
							<div class="panel-notifications"></div>
							<div class="panel-events"></div>
						</div>
					</div>
                    </li>
                    <li class="nav-item dropdown dropdown-message dropdown-mobile hidden">
					<a class="nav-link notifications-icon" data-toggle="dropdown" aria-haspopup="true"
						aria-expanded="true" href="javascript:;">
						<span>
							<i class="icontop fas fa-bell"></i>
						</span>
					</a>
                    </li>
                    <!-- <li class="nav-item dropdown dropdown-message dropdown-mobile">
					<a class="nav-link" href="/app/video-library" data-toggle="dropdown" aria-haspopup="true"
						aria-expanded="true" href="javascript:;">
						<span>
							<i class="icontop fa fa-play-circle"></i>
						</span>
					</a>
                    </li> -->
                    
                    <li class="nav-item dropdown dropdown-navbar-user dropdown-mobile">
					<a class="nav-link" href="{{ item.route }}" data-toggle="dropdown" aria-haspopup="true"
						aria-expanded="true" href="javascript:;">
						<span>
							<i class="icontop far fa-circle-play"></i>
						</span>
					</a>
						
                        <div class="dropdown-menu dropdown-menu-right" id="play-video">
                        </div>
                        
					<div class="dropdown-menu dropdown-menu-right" id="toolbar-user" role="menu">
						<a class="dropdown-item" href="{{ item.route }}" data-toggle="dropdown" aria-haspopup="true"
							aria-expanded="true" href="javascript:;">
							<span>
								<i class="icontop far fa-circle-play"></i>
							</span>
						</a>
					</div>
                    </li>
    
					<li class="nav-item dropdown dropdown-navbar-user dropdown-mobile">
						<a class="nav-link avatar-small" data-toggle="dropdown" href="javascript:;">
							{{ avatar }}
						</a>
						<div class="dropdown-menu dropdown-menu-right" id="toolbar-user" role="menu">
							{% for item in navbar_settings.settings_dropdown %}
								{% if (!item.hidden) { %}
									{% if (item.route) { %}
										<a class="dropdown-item" href="{{ item.route }}">
											{%= __(item.item_label) %}
										</a> {% } else if (item.action) { %}
										<a class="dropdown-item" onclick="return {{ item.action }}">
											{%= __(item.item_label) %}
										</a> {% } else { %}
										<div class="dropdown-divider"></div>
									{% } %}
								{% } %}
							{% endfor %}
						</div>
					</li>
                </ul>
				</ul>
			</div>
		</div>
	</div>

	<!-- bottom navbar -->
	<div class="navbar gpnav_bottom navbar-expand" role="navigation">
		
		<div class="container">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample05"
				aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarsExample05">
				<ul class="navbar-nav mr-auto">
					<li class="nav-item"> <a class="nav-link open-btn1" href="javascript:;"> Transactions </a> </li>
					<li class="nav-item"> <a class="nav-link open-btn2" href="javascript:;"> Reports </a> </li>

					<li class="nav-item">
						<a class="nav-link file-link custom-hide hidden" href="/app/file"> Docs </a>
					</li>

					<li class="nav-item" id="insight-link">
						<a class="nav-link file-link" href="#"> Insights </a>
					</li>
					{% if frappe.boot.get_acc_and_user %}
					<li class="nav-item" id="2b-link">
						<a class="nav-link file-link" href="/2b-app"> 2b Reco </a>
					</li>
					{% endif %}

					<li class="nav-item hidden" id="posapp-link">
						<a class="nav-link file-link" href="/app/posapp"> POS </a>
					</li>

					<!-- <li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdown" role="button"
							data-toggle="dropdown" aria-expanded="true"> Planning </a>

						<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
							<li><a class="dropdown-item" href="javascript:;"> Revenue Forecast</a> </li>
							<li><a class="dropdown-item" href="javascript:;"> Expense Forecast </a> </li>
							<li><a class="dropdown-item" href="javascript:;"> Budget Planning</a> </li>
							<li><a class="dropdown-item" href="javascript:;"> Actual Vs Budget</a> </li>

						</ul>
					</li> -->

				</ul>
				<form class="form-inline fill-width justify-content-end" role="search" onsubmit="return false;">
					<div class="frappe-control input-max-width" data-fieldtype="Select" data-fieldname="current_dashboard" title="current_dashboard">
							<div class="form-group" style="margin-bottom: auto">
								<div class="control-input-wrapper">
									<div class="control-input flex align-center">
										<select type="text" autocomplete="off" id="set_dashboard" class="input-with-feedback form-control ellipsis custom-select" maxlength="140" data-fieldtype="Select"
										data-fieldname="default_dashboard" placeholder="Select Dashboard" data-doctype="Session Defaults" style="max-width: 165px !important; min-width: 155px !important; text-align: left;height: 28px;border: 1px solid #ccc;font-size: 13px;color: #333;cursor: pointer;background: #fff;padding-top: 0;padding-bottom: 0;padding-left: 9% !important;">
											<option value="Select Dashboard" selected="true" disabled="disabled" style="width: 165px !important"> Select Dashboard </option>
											{% for i in navbar_settings.dashboard_selection %}
												<option style="width: 175px" class="dropdown-item" id="{%= i.id %}" {% if i.is_disabled %} disabled="disabled" {% endif %} value="{{i.dashboard}}" href="{{ i.route }}">
														{%= __(i.dashboard) %}
													<!-- <a href="{{ i.route }}" id="{{i.dashboard}}" style="text-decoration: none;"> {{i.dashboard}} </a> -->
												</option>
											{% endfor %}
										</select>
										<div class="select-icon">
											<svg class="icon icon-sm" style="margin-top: -8px">
												<use class="" href="#icon-select"></use>
											</svg>
										</div>
									</div>
								</div>
							</div>
				        </div>
					<div class="input-group search-bar hidden">
						<input id="navbar-search" placeholder="{%= __(' Search or type a command (Ctrl + G) ') %}"
							type="text" class="form-control" aria-haspopup="true" style="padding-left: 27px !important">
						<span class="search-icon">
							<svg class="icon icon-sm">
								<use xlink:href="#icon-search"></use>
							</svg>
						</span>
					</div>
				</form>
			</div>
		</div>
		
	</div>
	<!-- Transactions -->
	<div style="width: 940px;" class="sidebar_menu">
		<div class="content-inmenu ">
			<header class="heads_slidemenu">
				<a class="close-btn small">&times;</a>
				<h5> My Menu</h5>
				<hr class="mt-2 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />
			</header>
			<div class="mt-4 pr-2 sidebar_menu_scroll">
				<div class="position-relative nav nav-aside">
					<!-- employee_set -->
					{% for emp_role in frappe.boot.user.roles %}
					{% if emp_role == "Employee" %} 
					<div class="mb-2">
						<div class="titleMenus">
							
								<ul>
									<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label">Employee</li>
								</ul>
							
						</div>

						<div class="row">
							{% for item in frappe.boot.get_navbar_doctype.employee %}
							{% if item.is_transaction %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<span style="margin-bottom: 5px" class="nav-link">
										<a href="/app/{{item.route}}">{{item.doctype_name}} </a>
										<span class="ms-auto">
											<a href="/app/{{item.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="List"><i class="bi bi-list"></i></a>
											<a href="/app/{{item.route}}/new-{{item.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="Add"><i class="bi bi-plus"></i></a>
										</span>
									</span>
								</span>
							</div>
							{% endif %}
							
							{% endfor %}
						</div>
						
						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}
					{% endfor %}

					<!-- accounts_user_set -->
					{% if frappe.boot.get_acc_and_user %} 
					<div class="mb-2">
						<div class="titleMenus">
								<ul>
									<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label">Accounting</li>
								</ul>
							
						</div>

						<div class="row">
							{% for accounts_user in frappe.boot.get_navbar_doctype.accounts_user %}
							{% if accounts_user.is_transaction %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<span style="margin-bottom: 5px" class="nav-link">
										<a href="/app/{{accounts_user.route}}">{{accounts_user.doctype_name}} </a>
										<span class="ms-auto">
											<a href="/app/{{accounts_user.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="List"><i class="bi bi-list"></i></a>
											<a href="/app/{{accounts_user.route}}/new-{{accounts_user.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="Add"><i class="bi bi-plus"></i></a>
										</span>
									</span>
								</span>
							</div>
							{% endif %}
							
							{% endfor %}
						</div>
						
						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- purchase_set -->
					{% if frappe.boot.get_purchase_user_and_approver %} 
					<div class="mb-2">
						<div class="titleMenus">
								<ul>
									<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label">Buying</li>
								</ul>
							
						</div>

						<div class="row">
							{% for purchase in frappe.boot.get_navbar_doctype.purchase_user %}
							{% if purchase.is_transaction %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<span style="margin-bottom: 5px" class="nav-link">
										<a href="/app/{{purchase.route}}">{{purchase.doctype_name}} </a>
										<span class="ms-auto">
											<a href="/app/{{purchase.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="List"><i class="bi bi-list"></i></a>
											<a href="/app/{{purchase.route}}/new-{{purchase.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="Add"><i class="bi bi-plus"></i></a>
										</span>
									</span>
								</span>
							</div>
							{% endif %}
							
							{% endfor %}
						</div>
						
						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- sales_set -->
					{% if frappe.boot.get_sales_user_and_approver %} 
					<div class="mb-2">
						<div class="titleMenus">
								<ul>
									<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label">Selling</li>
								</ul>
							
						</div>

						<div class="row">
							{% for sales in frappe.boot.get_navbar_doctype.sales_user %}
							{% if sales.is_transaction %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<span style="margin-bottom: 5px" class="nav-link">
										<a href="/app/{{sales.route}}">{{sales.doctype_name}} </a>
										<span class="ms-auto">
											<a href="/app/{{sales.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="List"><i class="bi bi-list"></i></a>
											<a href="/app/{{sales.route}}/new-{{sales.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="Add"><i class="bi bi-plus"></i></a>
										</span>
									</span>
								</span>
							</div>
							{% endif %}
							
							{% endfor %}
						</div>
						
						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- hr_user_set -->
					{% if frappe.boot.get_hr_and_user %} 
					<div class="mb-2">
						<div class="titleMenus">
								<ul>
									<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label">Human Resource</li>
								</ul>
							
						</div>

						<div class="row">
							{% for hr_user in frappe.boot.get_navbar_doctype.hr_user %}
							{% if hr_user.is_transaction %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<span style="margin-bottom: 5px" class="nav-link">
										<a href="/app/{{hr_user.route}}">{{hr_user.doctype_name}} </a>
										<span class="ms-auto">
											<a href="/app/{{hr_user.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="List"><i class="bi bi-list"></i></a>
											<a href="/app/{{hr_user.route}}/new-{{hr_user.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="Add"><i class="bi bi-plus"></i></a>
										</span>
									</span>
								</span>
							</div>
							{% endif %}
							
							{% endfor %}
						</div>
						
						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- hr_manager_set -->
					<!-- {% for hr_manager_role in frappe.boot.user.roles %}
					{% if hr_manager_role == "HR Manager" %} 
					<div class="mb-2">
						<div class="titleMenus">
								<ul>
									<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label">HR Manager Transactions</li>
								</ul>
							
						</div>

						<div class="row">
							{% for hr_manager in frappe.boot.get_navbar_doctype.hr_manager %}
							{% if hr_manager.is_transaction %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<span style="margin-bottom: 5px" class="nav-link">
										<a href="/app/{{hr_manager.route}}">{{hr_manager.doctype_name}} </a>
										<span class="ms-auto">
											<a href="/app/{{hr_manager.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="List"><i class="bi bi-list"></i></a>
											<a href="/app/{{hr_manager.route}}/new-{{hr_manager.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="Add"><i class="bi bi-plus"></i></a>
										</span>
									</span>
								</span>
							</div>
							{% endif %}
							
							{% endfor %}
						</div>
						
						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}
					{% endfor %} -->

					<!-- payroll_user_set -->
					{% if frappe.boot.get_payroll_and_user %} 
					<div class="mb-2">
						<div class="titleMenus">
								<ul>
									<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label">Payroll</li>
								</ul>
							
						</div>

						<div class="row">
							{% for payroll_user in frappe.boot.get_navbar_doctype.payroll_user %}
							{% if payroll_user.is_transaction %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<span style="margin-bottom: 5px" class="nav-link">
										<a href="/app/{{payroll_user.route}}">{{payroll_user.doctype_name}} </a>
										<span class="ms-auto">
											<a href="/app/{{payroll_user.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="List"><i class="bi bi-list"></i></a>
											<a href="/app/{{payroll_user.route}}/new-{{payroll_user.route}}" style="padding:.5px 0 0 !important;"  class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="Add"><i class="bi bi-plus"></i></a>
										</span>
									</span>
								</span>
							</div>
							{% endif %}
							
							{% endfor %}
						</div>
						
						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<div class="mb-4 pb-4"></div>
					<!-- accounts_manager_set -->
					<!-- {% for accounts_manager_role in frappe.boot.user.roles %}
					{% if accounts_manager_role == "Accounts Manager" %} 
					<div class="mb-2">
						<div class="titleMenus">
								<ul>
									<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label">Accounts Manager Transactions</li>
								</ul>
							
						</div>

						<div class="row">
							{% for accounts_manager in frappe.boot.get_navbar_doctype.accounts_manager %}
							{% if accounts_manager.is_transaction %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<span style="margin-bottom: 5px" class="nav-link">
										<a href="/app/{{accounts_manager.route}}">{{accounts_manager.doctype_name}} </a>
										<span class="ms-auto">
											<a href="/app/{{accounts_manager.route}}" style="padding:.5px 0 0 !important;" class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="List"><i class="bi bi-list"></i></a>
											<a href="/app/{{accounts_manager.route}}/new-{{accounts_manager.route}}" style="padding:.5px 0 0 !important;"  class="btn btn-sm btn-white" data-toggle="tooltip"
												data-placement="top" title="Add"><i class="bi bi-plus"></i></a>
										</span>
									</span>
								</span>
							</div>
							{% endif %}
							
							{% endfor %}
						</div>
						
						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}
					{% endfor %} -->

				</div>

			</div>


		</div>
	</div>

	<!-- My Reports -->
	<div style="width: 1000px;" class="sidebar_menu reports">
		<div class="content-inmenu ">
			<header class="heads_slidemenu">
				<a class="close-btn small">&times;</a>
				<div class="d-flex justify-content-between align-items-center" style="width: 95%">
					<h5 class="mb-0">My Reports</h5>
					<a href="/app/organizational-chart" class="btn btn-sm btn-secondary-dark"><i
							class="bi bi-building text-white"></i>
						Organization Chart </a>
				</div>
				<hr class="mt-2 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />
			</header>
			<div class="mt-4 pr-2 sidebar_menu_scroll">
				<div class="position-relative nav nav-aside">
					<!-- employee_set -->
					{% for emp_role in frappe.boot.user.roles %}
					{% if emp_role == "Employee" %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">Employee</li>
							</ul>
						</div>

						<div style="margin-bottom: 5px" class="row">
							{% for emp_rep in frappe.boot.get_navbar_doctype.employee_report %}
							{% if emp_rep.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{emp_rep.route}}" class="nav-link">
										<span>{{emp_rep.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}

							{% for emp_master in frappe.boot.get_navbar_doctype.employee %}
							{% if emp_master.is_master %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{emp_master.route}}" class="nav-link">
										<span>{{emp_master.doctype_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
						</div>


						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}
					{% endfor %}

					<!-- accounts_user_set -->
					{% if frappe.boot.get_acc_and_user %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">Accounting</li>
							</ul>
						</div>

						<div class="row">
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/account/view/tree" class="nav-link">
										<span>Chart of Accounts</span>
									</a>
								</span>
							</div>
							{% for accounts_user in frappe.boot.get_navbar_doctype.accounts_user_report %}
							{% if accounts_user.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{accounts_user.route}}" class="nav-link">
										<span>{{accounts_user.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							
							{% endfor %}

							{% for accounts_user_master in frappe.boot.get_navbar_doctype.accounts_user %}
							{% if accounts_user_master.is_master %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{accounts_user_master.route}}" class="nav-link">
										<span>{{accounts_user_master.doctype_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
							<div class=" col-lg-4">
								<span class="nav-item" id="view-invoice-portal">
									<a href="#" class="nav-link">
										<span>Invoice Portal </span>
									</a>
								</span>
							</div> 
						</div>

						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- purchase_set -->
					{% if frappe.boot.get_purchase_user_and_approver %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">Buying</li>
							</ul>
						</div>

						<div class="row">
							{% for purchase_user in frappe.boot.get_navbar_doctype.purchase_user_report %}
							{% if purchase_user.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{purchase_user.route}}" class="nav-link">
										<span>{{purchase_user.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}

							{% for purchase_user_master in frappe.boot.get_navbar_doctype.purchase_user %}
							{% if purchase_user_master.is_master %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{purchase_user_master.route}}" class="nav-link">
										<span>{{purchase_user_master.doctype_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
						</div>

						

						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- sales_set -->
					{% if frappe.boot.get_sales_user_and_approver %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">Selling</li>
							</ul>
						</div>

						<div class="row">
							{% for sales_user in frappe.boot.get_navbar_doctype.sales_user_report %}
							{% if sales_user.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{sales_user.route}}" class="nav-link">
										<span>{{sales_user.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}

							{% for sales_user_master in frappe.boot.get_navbar_doctype.sales_user %}
							{% if sales_user_master.is_master %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{sales_user_master.route}}" class="nav-link">
										<span>{{sales_user_master.doctype_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
						</div>				

						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- hr_user_set -->
					{% if frappe.boot.get_hr_and_user %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">Human Resource</li>
							</ul>
						</div>

						<div class="row">
							{% for hr_user in frappe.boot.get_navbar_doctype.hr_user_report %}
							{% if hr_user.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{hr_user.route}}" class="nav-link">
										<span>{{hr_user.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}

							{% for hr_user_master in frappe.boot.get_navbar_doctype.hr_user %}
							{% if hr_user_master.is_master %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{hr_user_master.route}}" class="nav-link">
										<span>{{hr_user_master.doctype_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
						</div>

						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- hr_manager_set -->
					<!-- {% for hr_manager_role in frappe.boot.user.roles %}
					{% if hr_manager_role == "HR Manager" %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">HR Manager Reports & Masters</li>
							</ul>
						</div>

						<div class="row">
							{% for hr_manager in frappe.boot.get_navbar_doctype.hr_manager_report %}
							{% if hr_manager.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{hr_manager.route}}" class="nav-link">
										<span>{{hr_manager.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}

							{% for hr_manager_master in frappe.boot.get_navbar_doctype.hr_manager %}
							{% if hr_manager_master.is_master %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{hr_manager_master.route}}" class="nav-link">
										<span>{{hr_manager_master.doctype_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
						</div>

						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}
					{% endfor %} -->
					

					<!-- payroll_user_set -->
					{% if frappe.boot.get_payroll_and_user %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">Payroll</li>
							</ul>
						</div>

						<div class="row">
							{% for payroll_user in frappe.boot.get_navbar_doctype.payroll_user_report %}
							{% if payroll_user.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{payroll_user.route}}" class="nav-link">
										<span>{{payroll_user.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}

							{% for payroll_user_master in frappe.boot.get_navbar_doctype.payroll_user %}
							{% if payroll_user_master.is_master %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{payroll_user_master.route}}" class="nav-link">
										<span>{{payroll_user_master.doctype_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
						</div>

						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					<!-- stock_user_set -->
					{% if frappe.boot.get_payroll_and_user %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">Inventory</li>
							</ul>
						</div>

						<div class="row">
							{% for stock_user in frappe.boot.get_navbar_doctype.stock_user_report %}
							{% if stock_user.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{stock_user.route}}" class="nav-link">
										<span>{{stock_user.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
						</div>

						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}

					

					<div class="mb-4 pb-4"></div>
					<!-- accounts_manager_set -->
					<!-- {% for accounts_manager_role in frappe.boot.user.roles %}
					{% if accounts_manager_role == "Accounts Manager" %} 
					<div class="mt-1 mb-2">
						<div class="titleMenus">
							<ul>
								<li style="font-size: 12px;margin-bottom: 14px;font-family: Poppins, sans-serif !important;font-weight: 600;" class="nav-label mb-2">Accounts Manager Reports & Masters</li>
							</ul>
						</div>

						<div class="row">
							{% for accounts_manager in frappe.boot.get_navbar_doctype.accounts_manager_report %}
							{% if accounts_manager.is_active %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{accounts_manager.route}}" class="nav-link">
										<span>{{accounts_manager.report_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}

							{% for accounts_manager_master in frappe.boot.get_navbar_doctype.accounts_manager %}
							{% if accounts_manager_master.is_master %}
							<div class=" col-lg-4">
								<span class="nav-item">
									<a href="/app/{{accounts_manager_master.route}}" class="nav-link">
										<span>{{accounts_manager_master.doctype_name}} </span>
									</a>
								</span>
							</div> 
							{% endif %}
							{% endfor %}
						</div>

						<hr class="mt-4 mb-3" style="border-top: 1px solid rgba(0, 0, 0, .05);" />

					</div>
					{% endif %}
					{% endfor %} -->

					<!-- butt -->
					<!-- <div class="mt-1 mb-4">
						<div class="row mb-4 pb-4">
							<div class="col-lg-4">

								<a href="/app/organizational-chart" class="btn  btn-primary"><i
										class="bi bi-building text-white"></i>
									Organization Chart </a>

							</div>
						</div>

					</div> -->
					<!-- end chart butt -->
				</div>

			</div>


		</div>
	</div>
	
</header>

`;
