import './navbar.html';

$(document).ready(() => {
	frappe.call({
		method: "stanch.api.check_razorpay_documents",
		args: {},
		callback: function(r) {
			if(r.message[0] && r.message[1]){
				var roles = r.message[1]
				if (roles.includes("Accounts Manager")) {
					var my_banner_id = document.getElementById('bannerPopUp');
					my_banner_id.style.display = 'block';
				}
			}
		}
	})
	frappe.call({
		method: "stanch.api.user_roles",
		callback: function(response) {
			var roles = response.message;
			if (roles.includes("Employee") && roles.length === 1) {
				$(".custom-hide").toggleClass("hidden", true);
			}
			if(has_common(frappe.user_roles, ["HR User","HR Manager", "Accounts User", "Accounts Manager","System Manager", "Sales User", "Sales Approver", "Purchase User", "Purchase Approver", "Payroll User"])){
				$(".custom-hide").toggleClass("hidden", false);
			}else{
				$(".custom-hide").toggleClass("hidden", true);
			}
		}
	});
	function updateDashboardName() {
		var currentURL = window.location.href;
		var dashboardName;
		if (currentURL.includes("employee-dashboard-1")) {
		   dashboardName = "Employee Space";
		} else if (currentURL.includes("transaction-dashboard")) {
		   dashboardName = "Finance";
		} else if (currentURL.includes("company-admin-dashbo")) {
		   dashboardName = "Company Admin";
		} else if (currentURL.includes("hrms-dashboard")) {
		   dashboardName = "Human Resource";
		} else if (currentURL.includes("compliance-dashboard")) {
		   dashboardName = "Compliance";
		} else if (currentURL.includes("management-dashboard")) {
		   dashboardName = "Management";
		} else {
		   dashboardName = "Select Dashboard";
		}
		document.getElementById("set_dashboard").value = dashboardName;
	}
	 
	updateDashboardName();
	
	function updateDashboardLabel() {
		var currentURL = window.location.href;
		var dashboardName;
		if (!currentURL.includes("employee-dashboard-1") &&
		!currentURL.includes("transaction-dashboard") &&
		!currentURL.includes("company-admin-dashbo") &&
		!currentURL.includes("hrms-dashboard") &&
		!currentURL.includes("compliance-dashboard") &&
		!currentURL.includes("management-dashboard")) {
		dashboardName = "Select Dashboard";
		document.getElementById("set_dashboard").value = dashboardName;
		}
	}
	
	updateDashboardLabel();
	
	setInterval(function() {
		updateDashboardLabel();
	}, 1000);

		
 
	frappe.call({
		method: "stanch.api.checkins_enable_condition_check",
		callback: function(r) {
			if (r.message == 'ok'){
				frappe.call({
					method: 'stanch.api.get_login_detail',
					callback: function (r) {
						var a = document.getElementById('checkinout');
						var b = document.getElementById('cout');
						var c = document.getElementById('cin');
						if (r.message){
							if(r.message[0] == 'IN'){
								frappe.call({
									method: 'stanch.api.checkin_checkout_validate_restrict',
									callback: function (t) {
										if(t.message){
											if(t.message[0] == "IN"){
												a.textContent = "Check Out";
												b.textContent = "Checked In : " + t.message[1];
												a.toggleClass("active");
											}
											if(t.message[0] == "OUT"){
												a.textContent = "Check In";
												if (r.message[1]){
													c.textContent = "Checked Out : " + t.message[1];
												}
												a.toggleClass("active");
											}
											location.reload()
										}
									}
								})
							}
							if(r.message[0] == "OUT"){
								a.textContent = "Check In";
								if (r.message[1]){
									c.textContent = "Checked Out : " + r.message[1];
								}
								a.toggleClass("active");
							}
						}
					}
				})
			}
			if (r.message == 'no'){
				frappe.call({
					method: 'stanch.api.get_login_detail',
					callback: function (r) {
						var a = document.getElementById('checkinout');
						var b = document.getElementById('cout');
						var c = document.getElementById('cin');
						if (r.message){
						// $(this).text(function (i, v) { return v == "Check Out" ? "Check In" : "Check Out"; });
						// $(this).toggleClass("active");
							if(r.message[0] == 'IN'){
								a.textContent = "Check Out";
								b.textContent = "Checked In : " + r.message[1];
								a.toggleClass("active");
							}
							if(r.message[0] == "OUT"){
								a.textContent = "Check In";
							}
							if (r.message[1]){
								c.textContent = "Checked Out : " + r.message[1];
							}
							a.toggleClass("active");
						}
					}
				})
			}
		}
	})
	
	for (let item in frappe.boot.navbar_menu) {
		for (let i in frappe.boot.navbar_menu[item]) {
			if (i == 'master' || i == 'transactions') {
				let col = frappe.boot.navbar_menu[item][i]
				for (let c in col) {
					let docname = col[c].doctype_name
					if (!frappe.model.can_read(docname)){
						$("#" + col[c].id).attr('style', 'color: #9a9a9a !important');
						$("#" + col[c].id).addClass("disable-click");
					}
				}
			}
			
			let allowed_reports = frappe.boot.allowed_reports
			if (i == 'reports') {
				let col = frappe.boot.navbar_menu[item][i]
				for (let c in col) {
					let docname = col[c].report
					if (!allowed_reports.includes(docname)) {
						$("#" + col[c].id).addClass("disable-click");
						$("#" + col[c].id).attr('style', 'color: #9a9a9a !important');
					}
				}
			}
		}
	}
	
	let boards = frappe.boot.navbar_settings['dashboard_selection']
	for (let board in boards) {
		if (boards[board].permission && !has_common(boards[board].permission.split("\n"), frappe.user_roles)) {
			// $("#" + boards[board].id).addClass("disable-click");
			$("#" + boards[board].id).attr('disabled', 'disabled');
			// $("." + boards[board].dashboard).css('color', "#9a9a9a");
		}
	}
	
	document.getElementById('set_dashboard').addEventListener('change', function(r) {
		for (let board in boards){
			if (r.target.value == boards[board].dashboard && boards[board].route) {
				window.location.href = location.origin + boards[board].route
				document.getElementById('set_dashboard').text(boards[board].dashboard)
			}
		}
	});

	document.onreadystatechange = function () {
		if (document.readyState === 'complete') {
			const openBtn1 = document.querySelector('.open-btn1');
			const closeBtn1 = document.querySelector('.sidebar_menu .close-btn');
			const sidebar_menu = document.querySelector('.sidebar_menu');
			const openBtn2 = document.querySelector('.open-btn2');
			const closeBtn2 = document.querySelector('.reports .close-btn');
			const sidebar_menu_report = document.querySelector('.reports');

			$(".file-link").click(()=>{
				closeSidebar(sidebar_menu, openBtn1);
				closeSidebar(sidebar_menu_report, openBtn2);
			})

			function openSidebar(sidebar, openBtn, otherSidebar, otherOpenBtn) {
				sidebar.classList.add('sidebar-open');
				openBtn.classList.add('active'); // Add active class to openBtn
				otherSidebar.classList.remove('sidebar-open');
				otherOpenBtn.classList.remove('active'); // Remove active class from otherOpenBtn
			}

			function closeSidebar(sidebar, openBtn) {
				sidebar.classList.remove('sidebar-open');
				openBtn.classList.remove('active'); // Remove active class from openBtn
			}

			// Sidebar 1
			openBtn1.addEventListener('click', function (event) {
				event.stopPropagation(); // Prevent click event from bubbling up
				openSidebar(sidebar_menu, openBtn1, sidebar_menu_report, openBtn2);
			});

			closeBtn1.addEventListener('click', function () {
				closeSidebar(sidebar_menu, openBtn1);
			});

			// Sidebar 2
			openBtn2.addEventListener('click', function (event) {
				event.stopPropagation(); // Prevent click event from bubbling up
				openSidebar(sidebar_menu_report, openBtn2, sidebar_menu, openBtn1);
			});

			closeBtn2.addEventListener('click', function () {
				closeSidebar(sidebar_menu_report, openBtn2);
			});

			// Close both sidebars when clicking outside
			document.addEventListener('click', function (event) {
				const target = event.target;
				if (!sidebar_menu.contains(target) && target !== openBtn1 && !sidebar_menu_report.contains(target) && target !== openBtn2) {
					closeSidebar(sidebar_menu, openBtn1);
					closeSidebar(sidebar_menu_report, openBtn2);
				}
			});


			// Close both sidebars when clicking links inside the sidebar
			const sidebarLinks1 = document.querySelectorAll('.sidebar_menu a');
			sidebarLinks1.forEach(function (link) {
				link.addEventListener('click', function () {
					closeSidebar(sidebar_menu, openBtn1);
				});
			});

			const sidebarLinks2 = document.querySelectorAll('.reports a');
			sidebarLinks2.forEach(function (link) {
				link.addEventListener('click', function () {
					closeSidebar(sidebar_menu_report, openBtn2);
				});
			});
		}
	};

	// document.onreadystatechange = function () {
	// 	if (document.readyState === 'complete') {
	// 		const sidebar = document.querySelector('.sidebar_menu');
	// 		const openBtn = document.querySelector('.open-btn');
	// 		const closeBtn = document.querySelector('.close-btn');
	// 		const sidebarButtons = document.querySelectorAll('.sidebar_menu a');
	// 		// const anchorLinks = document.querySelectorAll('a[href^="#"]');



	// 		// Function to open the sidebar
	// 		function openSidebar() {
	// 			sidebar.classList.add('sidebar-open');
	// 			openBtn.classList.add('active'); // Add active class to openBtn
	// 		}

	// 		// Function to close the sidebar
	// 		function closeSidebar() {
	// 			sidebar.classList.remove('sidebar-open');
	// 			openBtn.classList.remove('active'); // Remove active class from openBtn

	// 		}

	// 		// Open sidebar when clicking open button
	// 		openBtn.addEventListener('click', function () {
	// 			openSidebar();
	// 		});

	// 		// Close sidebar when clicking close button
	// 		closeBtn.addEventListener('click', function () {
	// 			closeSidebar();
	// 		});

	// 		// Close sidebar when clicking outside
	// 		document.addEventListener('click', function (event) {
	// 			const target = event.target;
	// 			if (!sidebar.contains(target) && target !== openBtn) {
	// 				closeSidebar();
	// 			}
	// 		});
	// 	}

	// 	if (document.readyState === 'complete') {
	// 		const sidebar2 = document.querySelector('.reports');
	// 		const openBtn2 = document.querySelector('.open-btn_report');
	// 		const closeBtn2 = document.querySelector('.close-btn');
	// 		const sidebarButtons = document.querySelectorAll('.reports a');
	// 		// const anchorLinks = document.querySelectorAll('a[href^="#"]');

	// 		// Function to open the sidebar
	// 		function openSidebar() {
	// 			sidebar2.classList.add('sidebar-open');
	// 			openBtn2.classList.add('active'); // Add active class to openBtn
	// 		}

	// 		// Function to close the sidebar
	// 		function closeSidebar() {
	// 			sidebar2.classList.remove('sidebar-open');
	// 			openBtn2.classList.add('active'); // Add active class to openBtn
	// 		}

	// 		// Open sidebar when clicking open button
	// 		openBtn2.addEventListener('click', function () {
	// 			openSidebar();
	// 		});

	// 		// Close sidebar when clicking close button
	// 		closeBtn2.addEventListener('click', function () {
	// 			closeSidebar();
	// 		});

	// 		// Close sidebar when clicking outside
	// 		document.addEventListener('click', function (event) {
	// 			const target = event.target;
	// 			if (!sidebar2.contains(target) && target !== openBtn2) {
	// 				closeSidebar();
	// 			}
	// 		});

	// 	}

	// };

	document.getElementById('checkinout').addEventListener('click', function(e) {
		if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
		else { 
			var lat = ''
			var long = ''
			var geocodeUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`;
			fetch(geocodeUrl)
			.then(response => response.json())
			.then(data => {
				var address = data.address;
				const addressDetails = `${address.road}, ${address.neighbourhood}, ${address.city}`;
			frappe.call({
				method: 'stanch.api.get_login_type',
				callback: function (r) {
					var a = document.getElementById('checkinout');
					var b = document.getElementById('cout');
					var c = document.getElementById('cin');
					if(r.message){
						if (r.message[0] == "IN"){
							frappe.call({
								method: "stanch.api.checkins_enable_condition_check",
								callback: function(r) {
									if (r.message == 'ok'){
										frappe.call({
											method: 'stanch.api.check_in_out',
											args:{
												latitude: lat,
												longitude: long,
												address :addressDetails,
											},
											callback: function (e) {
												if (e.message[0] == "IN") {
													a.textContent = "Check Out";
													b.textContent = ""
													c.textContent = ""
													b.textContent = "Checked In : " + e.message[1];
													frappe.show_alert({
														message: __("Logged In"),
														indicator: "green",
													}, 5);
												}
												if (e.message[0] == "OUT") {
													a.textContent = "Check In";
													b.textContent = ""
													c.textContent = ""
													b.textContent = "Checked Out : " + e.message[1];
													frappe.show_alert({
														message: __("Logged Out"),
														indicator: "green",
													}, 5);
												}
											}
										})
									}
									else{
										frappe.confirm(__("Would you like to log out for the day?...Once you logged out you can't able to login again."), function() {
											frappe.call({
												method: 'stanch.api.check_in_out',
												args:{
													latitude: lat,
													longitude: long,
													address : addressDetails,
												},
												callback: function (e) {
													if (e.message[0] == "IN") {
														a.textContent = "Check Out";
														b.textContent = ""
														c.textContent = ""
														b.textContent = "Checked In : " + e.message[1];
														frappe.show_alert({
															message: __("Logged In"),
															indicator: "green",
														}, 5);
													}
													if (e.message[0] == "OUT") {
														a.textContent = "Check In";
														b.textContent = ""
														c.textContent = ""
														b.textContent = "Checked Out : " + e.message[1];
														frappe.show_alert({
															message: __("Logged Out"),
															indicator: "green",
														}, 5);
													}
												}
											});
										});
									}
								}
							});
						}
						if (r.message[0] == "OUT"){
							frappe.call({
								method: "stanch.api.checkins_enable_condition_check",
								callback: function(r) {
									if (r.message == 'ok'){
										frappe.call({
											method: 'stanch.api.multiple_checkins_create',
											args:{
												latitude: lat,
												longitude: long,
												address : addressDetails,

											},
											callback: function (s) {
												if (s.message[0] == "IN") {
													a.textContent = "Check Out";
													b.textContent = ""
													c.textContent = ""
													b.textContent = "Checked In : " + s.message[1];
													frappe.show_alert({
														message: __("Logged In"),
														indicator: "green",
													}, 5);
												}
												if (s.message[0] == "OUT") {
													a.textContent = "Check In";
													b.textContent = ""
													c.textContent = ""
													b.textContent = "Checked Out : " + s.message[1];
													frappe.show_alert({
														message: __("Logged Out"),
														indicator: "green",
													}, 5);
												}
											}
										});
									}
								}
							})
						}
					}
					else{
						frappe.call({
							method: 'stanch.api.check_in_out',
							args:{
								latitude: lat,
								longitude: long,
								address :addressDetails,

							},
							callback: function (e) {
								if (e.message[0] == "IN") {
									a.textContent = "Check Out";
									b.textContent = ""
									c.textContent = ""
									b.textContent = "Checked In : " +e.message[1];
									frappe.show_alert({
										message: __("Logged In"),
										indicator: "green",
									}, 5);
								}
								if (e.message[0] == "OUT") {
									a.textContent = "Check In";
									b.textContent = ""
									c.textContent = ""
									c.textContent = "Checked Out : " +e.message[1];
									frappe.show_alert({
										message: __("Logged Out"),
										indicator: "green",
									}, 5);
								}
							}
						});
					}
				}
			});
			})
        }
		function showPosition(position) {
            var lat = position.coords.latitude;
            var long = position.coords.longitude;
			var geocodeUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`;
			fetch(geocodeUrl)
			.then(response => response.json())
			.then(data => {
				var address = data.address;
				const addressDetails = `${address.road}, ${address.neighbourhood}, ${address.city}`;
			frappe.call({
				method: 'stanch.api.get_login_type',
				callback: function (r) {
					var a = document.getElementById('checkinout');
					var b = document.getElementById('cout');
					var c = document.getElementById('cin');
					if(r.message){
						if (r.message[0] == "IN"){
							frappe.call({
								method: "stanch.api.checkins_enable_condition_check",
								callback: function(r) {
									if (r.message == 'ok'){
										frappe.call({
											method: 'stanch.api.check_in_out',
											args:{
												latitude: lat,
												longitude: long,
												address :addressDetails,
											},
											callback: function (e) {
												if (e.message[0] == "IN") {
													a.textContent = "Check Out";
													b.textContent = ""
													c.textContent = ""
													b.textContent = "Checked In : " + e.message[1];
													frappe.show_alert({
														message: __("Logged In"),
														indicator: "green",
													}, 5);
												}
												if (e.message[0] == "OUT") {
													a.textContent = "Check In";
													b.textContent = ""
													c.textContent = ""
													b.textContent = "Checked Out : " + e.message[1];
													frappe.show_alert({
														message: __("Logged Out"),
														indicator: "green",
													}, 5);
												}
											}
										})
									}
									else{
										frappe.confirm(__("Would you like to log out for the day?...Once you logged out you can't able to login again."), function() {
											frappe.call({
												method: 'stanch.api.check_in_out',
												args:{
													latitude: lat,
													longitude: long,
													address :addressDetails,
												},
												callback: function (e) {
													if (e.message[0] == "IN") {
														a.textContent = "Check Out";
														b.textContent = ""
														c.textContent = ""
														b.textContent = "Checked In : " + e.message[1];
														frappe.show_alert({
															message: __("Logged In"),
															indicator: "green",
														}, 5);
													}
													if (e.message[0] == "OUT") {
														a.textContent = "Check In";
														b.textContent = ""
														c.textContent = ""
														b.textContent = "Checked Out : " + e.message[1];
														frappe.show_alert({
															message: __("Logged Out"),
															indicator: "green",
														}, 5);
													}
												}
											});
										});
									}
								}
							});
						}
						if (r.message[0] == "OUT"){
							frappe.call({
								method: "stanch.api.checkins_enable_condition_check",
								callback: function(r) {
									if (r.message == 'ok'){
										frappe.call({
											method: 'stanch.api.multiple_checkins_create',
											args:{
												latitude: lat,
												longitude: long,
												address :addressDetails,
											},
											callback: function (s) {
												if (s.message[0] == "IN") {
													a.textContent = "Check Out";
													b.textContent = ""
													c.textContent = ""
													b.textContent = "Checked In : " + s.message[1];
													frappe.show_alert({
														message: __("Logged In"),
														indicator: "green",
													}, 5);
												}
												if (s.message[0] == "OUT") {
													a.textContent = "Check In";
													b.textContent = ""
													c.textContent = ""
													b.textContent = "Checked Out : " + s.message[1];
													frappe.show_alert({
														message: __("Logged Out"),
														indicator: "green",
													}, 5);
												}
											}
										});
									}
								}
							})
						}
					}
					else{
						frappe.call({
							method: 'stanch.api.check_in_out',
							args:{
								latitude: lat,
								longitude: long,
								address :addressDetails,
							},
							callback: function (e) {
								if (e.message[0] == "IN") {
									a.textContent = "Check Out";
									b.textContent = ""
									c.textContent = ""
									b.textContent = "Checked In : " +e.message[1];
									frappe.show_alert({
										message: __("Logged In"),
										indicator: "green",
									}, 5);
								}
								if (e.message[0] == "OUT") {
									a.textContent = "Check In";
									b.textContent = ""
									c.textContent = ""
									c.textContent = "Checked Out : " +e.message[1];
									frappe.show_alert({
										message: __("Logged Out"),
										indicator: "green",
									}, 5);
								}
							}
						});
					}
				}
			});
		})
                    
		} 
		e.preventDefault()
		
		
	});
	
	document.getElementById('view-invoice-portal').addEventListener('click', function(e) {
		let dialog = new frappe.ui.Dialog({
			title: 'Invoice Portal',
			fields: [
				{
					label: 'Customer Name',
					fieldname: 'customer_name',
					fieldtype: 'Link',
					options: 'Customer'
				},
			],
			primary_action_label: 'View Invoice Portal',
			primary_action() {
				const data = dialog.get_values();
				let portalUrl =
				window.location.origin + "/invoice-portal?customer_name=" + encodeURIComponent(data.customer_name);
				window.open(portalUrl);
				dialog.hide();
			}
		});
		dialog.fields_dict.customer_name.get_query = function () {
			return {
				filters: {
					disabled: 0,
				},
			};
		};
		dialog.show();
	});
	document.getElementById('qrcode-icon').addEventListener('click', function (event) {
		event.stopPropagation();
	 
		function generateQRCode(platform) {
			var androidURL = "https://play.google.com/store/apps/details?id=com.stanch.payroll_hrm";
			var iosURL = "https://apps.apple.com/in/app/stanch/id6499520745";
		
			var url = platform === 'Android' ? androidURL : iosURL;
		
			// Generate QR code
			var qrCode = new QRious({
				value: url,
				size: 150
			});
		
			// Create popup container for QR code
			var popupContainer = document.createElement('div');
			popupContainer.id = 'popupContainer';
			popupContainer.className = 'popup-container'; 
			popupContainer.style.position = 'fixed'; 
			popupContainer.style.top = '22%';
			popupContainer.style.left = '87%';
			popupContainer.style.transform = 'translate(-50%, -50%)';
			popupContainer.style.backgroundColor = '#fff';
			popupContainer.style.padding = '20px';
			popupContainer.style.width = '206px';
			popupContainer.style.display = 'flex';
			popupContainer.style.alignItems = 'center';
			popupContainer.style.borderRadius = '10px';
			popupContainer.style.flexDirection = 'column';
			popupContainer.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.3)';
			popupContainer.style.zIndex = '9999';
		
			// Create QR code image element
			var qrImage = new Image();
			qrImage.src = qrCode.toDataURL();
			popupContainer.appendChild(qrImage);
		
			// Create text element
			var textElement = document.createElement('p');
			textElement.textContent = 'Scan the QR code for ' + platform + ' Application';
			textElement.style.marginTop = '10px';
			textElement.style.textAlign = 'center';
			popupContainer.appendChild(textElement);
		
			document.body.appendChild(popupContainer);
		
			popupContainer.addEventListener('click', function () {
				document.body.removeChild(popupContainer);
				document.body.removeChild(overlay);
			});
		}
	 
		// Create options container
		var choicesContainer = document.createElement('div');
		choicesContainer.id = 'choices-container'; 
		choicesContainer.style.position = 'fixed'; 
		choicesContainer.style.top = event.clientY + 'px'; 
		choicesContainer.style.left = event.clientX + 'px';
		choicesContainer.style.backgroundColor = '#fff';
		choicesContainer.style.padding = '20px';
		choicesContainer.style.borderRadius = '10px';
		choicesContainer.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.3)';
		choicesContainer.style.zIndex = '9999';
	 
		// Create Android option
		var androidChoice = document.createElement('div');
		androidChoice.textContent = 'Android';
		androidChoice.className = 'platform-option';
		androidChoice.style.cursor = 'pointer';
		androidChoice.style.marginBottom = '10px';
		androidChoice.addEventListener('click', function () {
			generateQRCode('Android');
			document.body.removeChild(choicesContainer);
		});
		choicesContainer.appendChild(androidChoice);
	 
		// Create iOS option
		var iosChoice = document.createElement('div');
		iosChoice.textContent = 'iOS';
		iosChoice.className = 'platform-option';
		iosChoice.style.cursor = 'pointer';
		iosChoice.addEventListener('click', function () {
			generateQRCode('iOS');
			document.body.removeChild(choicesContainer);
		});
		choicesContainer.appendChild(iosChoice);
	 
		document.body.appendChild(choicesContainer);
	 
		var overlay = document.createElement('div');
		overlay.id = 'overlay'; 
		overlay.style.position = 'fixed';
		overlay.style.top = '0';
		overlay.style.left = '0';
		overlay.style.width = '100%';
		overlay.style.height = '100%';
		overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
		overlay.style.zIndex = '9998';
		document.body.appendChild(overlay);
	 
		overlay.addEventListener('click', function () {
			document.body.removeChild(choicesContainer);
			document.body.removeChild(overlay);
		});
	 
		choicesContainer.addEventListener('click', function (event) {
		    	event.stopPropagation();
		});
	 
		function closeChoices() {
			document.body.removeChild(choicesContainer);
			document.body.removeChild(overlay);
			document.removeEventListener('click', closeChoices);
		}
		document.addEventListener('click', closeChoices);

		overlay.addEventListener('click', function () {
				document.body.removeChild(overlay);
				document.body.removeChild(popupContainer);
		});
		
		androidChoice.addEventListener('mouseenter', function () {
			androidChoice.style.backgroundColor = '#dddddd'; 
		});
		
		androidChoice.addEventListener('mouseleave', function () {
			androidChoice.style.backgroundColor = '';
		});
		
		iosChoice.addEventListener('mouseenter', function () {
			iosChoice.style.backgroundColor = '#dddddd'; 
		});
		
		iosChoice.addEventListener('mouseleave', function () {
			iosChoice.style.backgroundColor = '';
		});
		 
	});
	 
	});